import Flickity from 'flickity';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';
import 'select2/dist/js/select2.min.js';
import AOS from 'aos';
import 'flickity/dist/flickity.pkgd.js';

// sticky header variable
var shrinkHeader = 100;

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on all pages

    // Flickity.defaults.dragThreshold = 3;
  },
  finalize() {

    AOS.init();

    // JavaScript to be fired on all pages, after page specific JS is fired

    let mapsSectionWidth = checkMapWidth();

    if ( mapsSectionWidth == true) {
      $('#map-address-wrp').addClass('flickity-now');
      mapSectionSlider()
    }

    else {
      mapSectionSliderDestroy()
    }

    let wpcf7Element = document.querySelector('#motor-calculator-section.automotive-calculator .calculator-contact-form-wrp .wpcf7');

    if (wpcf7Element) {
      wpcf7Element.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();
        $('#motor-calculator-section.automotive-calculator').addClass('invalid-form');
      }, false );

      wpcf7Element.addEventListener( 'wpcf7mailsent', function(event) {
        event.preventDefault();
        $('#motor-calculator-section.automotive-calculator').removeClass('invalid-form');
      }, false );
    }

    let wpcf7ElementNew = document.querySelector('#motor-calculator-section.heavy-vehicle-calculator .calculator-contact-form-wrp .wpcf7');

    if (wpcf7ElementNew) {
      wpcf7ElementNew.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();
        $('#motor-calculator-section.heavy-vehicle-calculator').addClass('invalid-form');
      }, false );

      wpcf7ElementNew.addEventListener( 'wpcf7mailsent', function(event) {
        event.preventDefault();
        $('#motor-calculator-section.heavy-vehicle-calculator').removeClass('invalid-form');
      }, false );
    }

    // Add Apllications for Motor Calculations
    let wpcf7AccordionForm = document.querySelector('.accordion-form-section .wpcf7');

    if (wpcf7AccordionForm) {
      wpcf7AccordionForm.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();

        $('.accordion-form-section .accordion-item-wrp').each(function() {
          $(this).find('.faq-title').addClass('collapsed');
          $(this).find('.faq-content').removeClass('show');
        });

        $('.accordion-form-section .accordion-item-wrp').first().find('.faq-title').removeClass('collapsed');
        $('.accordion-form-section .accordion-item-wrp').first().find('.faq-content').addClass('show');
      }, false );
    }
    // Add Apllications for Motor Calculations

    // Slope Calculations
    $('#motor-calculator-section.heavy-vehicle-calculator input#angle-of-slope').on('keyup change', function() {
      let slopeAngle = $('.heavy-vehicle-calculator #angle-of-slope').val();
      $('.heavy-vehicle-calculator #angle-of-slope-percentage').val((Math.tan(slopeAngle*Math.PI/180)*100).toFixed(2));
      if (slopeAngle == 0) {
        $('.heavy-vehicle-calculator #angle-of-slope-ratio').val(0);
      } else {
        $('.heavy-vehicle-calculator #angle-of-slope-ratio').val((1/(Math.tan(slopeAngle*Math.PI/180))).toFixed(2));
      }
    });

    $('#motor-calculator-section.heavy-vehicle-calculator input#angle-of-slope-percentage').on('keyup change', function() {
      let slopeAngle = $('.heavy-vehicle-calculator #angle-of-slope-percentage').val();
      $('.heavy-vehicle-calculator #angle-of-slope').val(((Math.atan(slopeAngle/100))*180/Math.PI).toFixed(0));

      if (slopeAngle == 0) {
        $('.heavy-vehicle-calculator #angle-of-slope-ratio').val(0);
      } else {
        $('.heavy-vehicle-calculator #angle-of-slope-ratio').val((100/slopeAngle).toFixed(2));
      }
    });

    $('#motor-calculator-section.heavy-vehicle-calculator input#angle-of-slope-ratio').on('keyup change', function() {
      let slopeAngle = $('.heavy-vehicle-calculator #angle-of-slope-ratio').val();

      if (slopeAngle == 0) {
        $('.heavy-vehicle-calculator #angle-of-slope').val(0);
        $('.heavy-vehicle-calculator #angle-of-slope-percentage').val(0);
      } else {
        $('.heavy-vehicle-calculator #angle-of-slope').val(((Math.atan(1/slopeAngle))*180/Math.PI).toFixed(0));
        $('.heavy-vehicle-calculator #angle-of-slope-percentage').val((100/slopeAngle).toFixed(2));
      }
    });
    // Slope Calculations

    // Listen for Input Number Field Changes on Calculator Page and Run Calculations, Validations
    $('#motor-calculator-section.automotive-calculator input').not('#motor-calculator-section .calculator-contact-form-wrp input , #motor-calculator-section .radio-wrp input').on('keyup change', function() {
      motorCalculator();
      // $(this).valid();
    });

    $('#motor-calculator-section.heavy-vehicle-calculator input').not('#motor-calculator-section .calculator-contact-form-wrp input , #motor-calculator-section .radio-wrp input').on('keyup change', function() {
      motorCalculator();
      // $(this).valid();
    });

    // Listen for Radio Motor Changes on Calculator Page and Run Calculations without Validations
    $('#motor-calculator-section.automotive-calculator .radio-wrp input').on('keyup change', function() {
      motorCalculator();
    });

    $('#motor-calculator-section.heavy-vehicle-calculator .radio-wrp input').on('keyup change', function() {
      motorCalculator();
    });

    // Listen for Input Changes on Product Listing Page and Run Calculations, Validations
    $('.motor-items-wrp .motor-item input').on('keyup change', function() {
      let calculatorSection = $(this).parents('.motor-item');
      motorListingCalculator(calculatorSection);
      // $(this).valid();
    });

    // Run Calculations in Calculations Page on Page Load
    $('.motor-items-wrp .motor-item').each(function() {
      let test = $(this);
      motorListingCalculator(test);
    });

    // Calculations Page Proceed Button Click
    $('#motor-calculator-section.automotive-calculator #calculator-proceed-button').click(function(e){
      e.preventDefault();

      $('#motor-calculator-section.automotive-calculator').removeClass('step-1');
      $('#motor-calculator-section.automotive-calculator').addClass('step-2');
      $('#motor-calculator-section.automotive-calculator #motor-selection').css({'display':'none'});
      let selectedMotor = $('#motor-calculator-section.automotive-calculator input[name="motor-select-automotive"]:checked').val();
      let selectedMotorSeperated = selectedMotor.split('-');
      let selectedMotorId = parseFloat(selectedMotorSeperated[1]);

      $('#motor-calculator-section.automotive-calculator .motor-details-wrp .form-motor-item-wrp').each(function() {
        let imageWrp = $(this).find('.img-wrp');
        if ($(this).is(`#motor-${selectedMotorId}`)) {
          $(this).fadeIn(500);
          $('#motor-calculator-section.automotive-calculator .change-btn-wrp').appendTo(imageWrp);
          $('#motor-calculator-section.automotive-calculator .change-btn-wrp').removeClass('d-none');
        }
      });

      $('#motor-calculator-section.automotive-calculator .calculator-inner-wrp').css({'display':'none'});
      $('#motor-calculator-section.automotive-calculator .calculator-form-wrp').fadeIn(500);
    });

    // Calculations Page Goto Edit Button CLick
    $('#motor-calculator-section.automotive-calculator #goto-edit-calculator').click(function(e){
      e.preventDefault();

      $('#motor-calculator-section.automotive-calculator').addClass('step-1');
      $('#motor-calculator-section.automotive-calculator').removeClass('step-2');
      $('#motor-calculator-section.automotive-calculator .calculator-page .wpcf7-form-control-wrap').children('.wpcf7-not-valid-tip').css('display', 'none');
      $('#motor-calculator-section.automotive-calculator .calculator-contact-form-wrp').find('.wpcf7-response-output').css('display', 'none');

      $('#motor-calculator-section.automotive-calculator .motor-details-wrp .form-motor-item-wrp').each(function() {
        $(this).css('display', 'none');
      });

      $('#motor-calculator-section.automotive-calculator #motor-selection').fadeIn(500);

      $('#motor-calculator-section.automotive-calculator .calculator-inner-wrp').fadeIn(500);
      $('#motor-calculator-section.automotive-calculator .calculator-form-wrp').css({'display':'none'})
    });

    // Calculations Page CF7 On Mail Sent Function
    if (document.querySelector( '#motor-calculator-section.automotive-calculator .calculator-contact-form-wrp .wpcf7' )) {
      var wpcf7Elm = document.querySelector( '#motor-calculator-section.automotive-calculator .calculator-contact-form-wrp .wpcf7');

      wpcf7Elm.addEventListener( 'wpcf7submit', function() {
        $('#motor-calculator-section.automotive-calculator .calculator-page .wpcf7-form-control-wrap').children('.wpcf7-not-valid-tip').css('display','block');
        $('#motor-calculator-section.automotive-calculator .calculator-contact-form-wrp').find('.wpcf7-response-output').css('display', 'block');
      }, false );

      wpcf7Elm.addEventListener( 'wpcf7mailsent', function() {
        let selectedMotor = $('#motor-calculator-section.automotive-calculator input[name="motor-select-automotive"]:checked').val();
        let selectedMotorSeperated = selectedMotor.split('-');
        let selectedMotorId = parseFloat(selectedMotorSeperated[1]);

        $('#motor-calculator-section.automotive-calculator .motor-datasheets-wrp .motor-datasheet-item').each(function() {
          if ($(this).is(`#motor-${selectedMotorId}`)) {
            // $(this).removeClass('d-none');
            $(this).fadeIn(500);
          }
        });

        $('#motor-calculator-section.automotive-calculator .calculator-form-wrp').css({'display':'none'})
        $('#motor-calculator-section.automotive-calculator .motor-details-wrp .form-motor-item-wrp').each(function() {
          // $(this).addClass('d-none');
          $(this).css({'display':'none'});
        });
      }, false );
    }

    // Calculations Page Proceed Button Click Heavy Vehicle
    $('#motor-calculator-section.heavy-vehicle-calculator #calculator-proceed-button').click(function(e){
      e.preventDefault();

      $('#motor-calculator-section.heavy-vehicle-calculator').removeClass('step-1');
      $('#motor-calculator-section.heavy-vehicle-calculator').addClass('step-2');
      $('#motor-calculator-section.heavy-vehicle-calculator #motor-selection').css({'display':'none'});
      let selectedMotor = $('#motor-calculator-section.heavy-vehicle-calculator input[name="motor-select-heavy-vehicle"]:checked').val();
      let selectedMotorSeperated = selectedMotor.split('-');
      let selectedMotorId = parseFloat(selectedMotorSeperated[1]);

      $('#motor-calculator-section.heavy-vehicle-calculator .motor-details-wrp .form-motor-item-wrp').each(function() {
        let imageWrp = $(this).find('.img-wrp');
        if ($(this).is(`#motor-${selectedMotorId}`)) {
          $(this).fadeIn(500);
          $('#motor-calculator-section.heavy-vehicle-calculator .change-btn-wrp').appendTo(imageWrp);
          $('#motor-calculator-section.heavy-vehicle-calculator .change-btn-wrp').removeClass('d-none');
        }
      });

      $('#motor-calculator-section.heavy-vehicle-calculator .calculator-inner-wrp').css({'display':'none'});
      $('#motor-calculator-section.heavy-vehicle-calculator .calculator-form-wrp').fadeIn(500);
    });

    // Calculations Page Goto Edit Button CLick
    $('#motor-calculator-section.heavy-vehicle-calculator #goto-edit-calculator').click(function(e){
      e.preventDefault();

      $('#motor-calculator-section.heavy-vehicle-calculator').addClass('step-1');
      $('#motor-calculator-section.heavy-vehicle-calculator').removeClass('step-2');
      $('#motor-calculator-section.heavy-vehicle-calculator .calculator-page .wpcf7-form-control-wrap').children('.wpcf7-not-valid-tip').css('display', 'none');
      $('#motor-calculator-section.heavy-vehicle-calculator .calculator-contact-form-wrp').find('.wpcf7-response-output').css('display', 'none');

      $('#motor-calculator-section.heavy-vehicle-calculator .motor-details-wrp .form-motor-item-wrp').each(function() {
        $(this).css('display', 'none');
      });

      $('#motor-calculator-section.heavy-vehicle-calculator #motor-selection').fadeIn(500);

      $('#motor-calculator-section.heavy-vehicle-calculator .calculator-inner-wrp').fadeIn(500);
      $('#motor-calculator-section.heavy-vehicle-calculator .calculator-form-wrp').css({'display':'none'})
    });

    // Calculations Page CF7 On Mail Sent Function
    if (document.querySelector( '#motor-calculator-section.heavy-vehicle-calculator .calculator-contact-form-wrp .wpcf7' )) {
      var wpcf7ElmHeavyVehicle = document.querySelector( '#motor-calculator-section.heavy-vehicle-calculator .calculator-contact-form-wrp .wpcf7');

      wpcf7ElmHeavyVehicle.addEventListener( 'wpcf7submit', function() {
        $('#motor-calculator-section.heavy-vehicle-calculator .calculator-page .wpcf7-form-control-wrap').children('.wpcf7-not-valid-tip').css('display','block');
        $('#motor-calculator-section.heavy-vehicle-calculator .calculator-contact-form-wrp').find('.wpcf7-response-output').css('display', 'block');
      }, false );

      wpcf7ElmHeavyVehicle.addEventListener( 'wpcf7mailsent', function() {
        let selectedMotor = $('#motor-calculator-section.heavy-vehicle-calculator input[name="motor-select-heavy-vehicle"]:checked').val();
        let selectedMotorSeperated = selectedMotor.split('-');
        let selectedMotorId = parseFloat(selectedMotorSeperated[1]);

        $('#motor-calculator-section.heavy-vehicle-calculator .motor-datasheets-wrp .motor-datasheet-item').each(function() {
          if ($(this).is(`#motor-${selectedMotorId}`)) {
            // $(this).removeClass('d-none');
            $(this).fadeIn(500);
          }
        });

        $('#motor-calculator-section.heavy-vehicle-calculator .calculator-form-wrp').css({'display':'none'})
        $('#motor-calculator-section.heavy-vehicle-calculator .motor-details-wrp .form-motor-item-wrp').each(function() {
          // $(this).addClass('d-none');
          $(this).css({'display':'none'});
        });
      }, false );
    }

    // Insights & News Load More
    $(document).ready(function () {
      $('.insights-item-v1').slice(0, 1).show();
      $('.insights-item-v2').slice(0, 1).show();
      if ($('.insights-item-v1:hidden').length != 0 || $('.insights-item-v2:hidden').length != 0) {
        $('#loadMoreInsights').show();
      } else {
        $('#loadMoreInsights').hide();
        $('#loadMoreInsights').text('').fadeOut('slow');
        $('#loadMoreInsights').addClass('load-more-end');
      }
      $('#loadMoreInsights').on('click', function (e) {
        e.preventDefault();
        $('.insights-item-v1:hidden').slice(0, 1).slideDown();
        $('.insights-item-v2:hidden').slice(0, 1).slideDown();
        if ($('.insights-item-v1:hidden').length == 0) {
          $('#loadMoreInsights').text('No More to view').fadeOut('slow');
          $('#loadMoreInsights').addClass('load-more-end');
        }
      });
    });

    //map-section popup+
    $('.contact-map-section #map-address-wrp .title-wrp-outer').each(function () {
      $(this).click(function() {
        $('.map-details').slideUp();
        $(this).next('.map-details').slideDown();
      });
    });

    $('.contact-map-section .flickity-button').click(function () {
      $('.show-address .map-details').slideUp();
      $('.item-wrp').each(function () {
        $(this).removeClass('show-address');
      });
    });
    //map-section popup

    // Product Listing Button Action
    $('.product-listing-view-datasheet').click(function (e) {
      e.preventDefault();
      $(this).slideUp();
      $(this).siblings('.product-listing-view-datasheet-content').slideDown();
    });

    var wpcf7ElmListing = document.querySelectorAll( '.products-listing-section .motor-items-wrp .wpcf7' );

    for (let i = 0; i <wpcf7ElmListing.length; i++) {
      wpcf7ElmListing[i].addEventListener( 'wpcf7mailsent', function() {
        window.open($(this).parent().siblings('.datasheet-link').attr('href'));
      }, false );
    }
    // Product Listing Button Action

    // Insights & News Mobile Load More
    $(document).ready(function () {
      $('.insights-item-mobile').slice(0, 2).show();
      if ($('.insights-item-mobile:hidden').length != 0) {
        $('#loadMoreInsightsMobile').show();
      } else {
        $('#loadMoreInsightsMobile').hide();
        $('#loadMoreInsightsMobile').text('').fadeOut('slow');
        $('#loadMoreInsightsMobile').addClass('load-more-end');
      }
      $('#loadMoreInsightsMobile').on('click', function (e) {
        e.preventDefault();
        $('.insights-item-mobile:hidden').slice(0, 2).slideDown();
        if ($('.insights-item-mobile:hidden').length == 0) {
          $('#loadMoreInsightsMobile').text('No More to view').fadeOut('slow');
          $('#loadMoreInsightsMobile').addClass('load-more-end');
        }
      });
    });

    // toggle class on hamburger icon click
    $('.mobile-burger-icon').click(function () {
      $('body, .mobile-burger-icon').toggleClass('show-menu');
    });

    // mobile sub menu navigation
    // level 1
    $('.mobile-menu .nav > li.menu-item-has-children').each(function () {
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
      $(this).find('> a').click(function (e) {
          e.preventDefault();
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // level 2
    $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function () {
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
      $(this).find('> a').click(function (e) {
          e.preventDefault();
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // return link click
    $('.return-link').each(function () {
      $(this).click(function () {
        $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
      });
    });

    // set header height to sticky overlay
    $('.sticky-overlay').outerHeight($('.header').outerHeight());


    // Cookie Policy Set up
    //check to see if the submited cookie is set, if not check if the popup has been closed, if not then display the popup
    if( getCookie('popupCookie') != 'submited'){
      if(getCookie('popupCookie') != 'closed' ){
        setTimeout(function(){$('.cookie-wrp').css('display', 'block').hide().fadeIn();}, 3000);
      }
    }

    $('a.close-cookie').click(function(){
      $('.cookie-wrp').fadeOut();
      //sets the coookie to one minute (.00069444444) if the popup is closed (whole numbers = days)
      setCookie( 'popupCookie', 'closed', 1 );
    });

    $('a.accept-cookie').click(function(){
      $('.cookie-wrp').fadeOut();
      //sets the coookie to five minutes (.0034722222 ) if the popup is submited (whole numbers = days)
      setCookie( 'popupCookie', 'submited', 100);
    });
    // Cookie Policy Set up

    formScripts();
    cardSectionGridSlider();
    cardSectionSlider();
    cardSectionTimeline();
    gallerySectionSlider();
    jqueryViewer('#gallery-images-viewer-v2');
    jqueryViewer('#gallery-images-viewer-v1');
    fullWidthVideoPlayer();
    videoPopupPlayer();
    delayHomeBannerVideo();
    slideUpMenuOption();
    footerTicker();
    productSectionGridSlider();
    productSectionSlider();
    checkSectionsForGaps();
    productListingItem();
    testimonialsSlider();
    customMotorForm();
    newCalculator();
    motorCalculator();
    marineCalculator();
    fanPowerCalculator();
    setTimeout(scrollToSection, 100);
    cardSectionVideoPlay();
    editorContentAdditions();
    twoColumnSectionVideoPlay();
  },
};

$(window).on('hashchange', scrollToSection);

$(window).on('resize', function () {
  let mapSectionWidthResize = checkMapWidth();

  if ( mapSectionWidthResize == true) {
    $('#map-address-wrp').addClass('flickity-now');
    mapSectionSlider()
  }

  $('.fcp-testimonials-section .testimonials-wrp, .cards-section.v1 .cards-wrp, .cards-section.v2 .cards-wrp, .cards-section.v3 .cards-wrp, .products-section.v1 .products-wrp, .products-section.v2 .products-wrp, .gallery-section.v1 .gallery-wrapper, .products-listing-section .product-carousel-nav, .products-listing-section .product-nav').flickity('resize');
});

$(window).on('load', function () {
  $('.fcp-testimonials-section .testimonials-wrp, .cards-section.v1 .cards-wrp, .cards-section.v2 .cards-wrp, .cards-section.v3 .cards-wrp, .products-section.v1 .products-wrp, .products-section.v2 .products-wrp, .gallery-section.v1 .gallery-wrapper, .products-listing-section .product-carousel-nav, .products-listing-section .product-nav',).flickity('resize');
});

//map slider
function mapSectionSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $(
    '.contact-map-section .main-carousel.flickity-now'
  ).flickity({
    cellAlign: cellAlignValue,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    initialIndex: 2,
  });
}

function mapSectionSliderDestroy() {
  $('.contact-map-section .main-carousel.flickity-now').flickity('destroy');
}

// sticky menu
$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }

  $('.sticky-overlay').outerHeight($('.header').outerHeight());
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// sticky menu

function formScripts() {
  if ($('.form-dropdown')) {
    $('.form-dropdown').each(function() {
      $(this).select2({
        dropdownParent: $(this).parent().parent(),
      });
    });
  }

  if ($('.select2-calculator')) {
    $('.select2-calculator').each(function() {
      $(this).select2({
        dropdownParent: $(this).parent(),
      });
      $(this).parent().addClass('d-none');
    });
  }

  $('.button-wrp-outter').append($('.wpcf7-response-output'));
}

function cardSectionGridSlider() {
  $('.cards-section.v1 .section-inner-wrp .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function cardSectionVideoPlay() {
  $('.card-video-wrp').each(function() {
    $(this).parent().hover(
      function() {
          // Add class to trigger CSS changes and play video
          $(this).children('.card-video-wrp').addClass('hovering');
          $(this).find('video')[0].play();
          $(this).find('video')[0].muted = false;
      },
      function() {
          // Remove class to revert CSS changes and pause video
          $(this).children('.card-video-wrp').removeClass('hovering');
          $(this).find('video')[0].pause();
          $(this).find('video')[0].muted = true;
      }
    );
  });
}

function twoColumnSectionVideoPlay() {
  $('.two-column-video-wrp').each(function() {
    $(this).parent().hover(
      function() {
          // Add class to trigger CSS changes and play video
          $(this).children('.two-column-video-wrp').addClass('hovering');
          $(this).find('video')[0].play();
          $(this).find('video')[0].muted = false;
      },
      function() {
          // Remove class to revert CSS changes and pause video
          $(this).children('.two-column-video-wrp').removeClass('hovering');
          $(this).find('video')[0].pause();
          $(this).find('video')[0].muted = true;
      }
    );
  });
}


function cardSectionSlider() {
    $('.cards-section.v2').each(function() {
      var cellAlignValue = '';

      if (matchMedia('screen and (max-width: 768px)').matches) {
        cellAlignValue = 'center';
      } else {
        cellAlignValue = 'left';
      }

      $(this).find('.cards-wrp').flickity({
        pageDots: true,
        prevNextButtons: true,
        wrapAround: true,
        cellAlign: cellAlignValue,
      });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function cardSectionTimeline() {
  $('.cards-section.v3').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.cards-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: false,
      cellAlign: cellAlignValue,
      groupCells: 1,
      contain: true,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function productSectionGridSlider() {
  $('.products-section.v1 .section-inner-wrp .products-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function productSectionSlider() {
  $('.products-section.v2').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.products-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function testimonialsSlider() {
  $('.fcp-testimonials-section').each(function() {
    $('.fcp-testimonials-section .testimonials-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: 'center',
      adaptiveHeight: true,
    });

    $('.fcp-testimonials-section .testimonials-wrp').find('.flickity-button').appendTo($('.fcp-testimonials-section .testimonials-wrp-outer').find('.flickity-wrp'));
  });
}


function gallerySectionSlider() {
  $('.gallery-section.v1').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.gallery-wrp-slider').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-wrp'));
    $(this).find('.flickity-button').appendTo($(this).find('.pagination-wrp'));
  });
}

function productListingItem() {

  var counter = 0;
  $('.products-listing-section .sliders-wrp').each(function(){
    var productNavClassName = '.product-nav-'+ counter;
    var mainCarousel = $(this).find(productNavClassName);
    var asNavCarousel =  $(this).find('.product-carousel-nav-'+ counter);

    // 1st carousel, main
    $(mainCarousel).flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
    });

    // 2nd carousel, navigation
    $(asNavCarousel).flickity({
      asNavFor: productNavClassName,
      contain: true,
      pageDots: false,
      prevNextButtons: false,
    });
    counter++;
  });
}

// Implementation of Jquery Viewer
function jqueryViewer(ulId) {
  var $galleryImages = $(ulId);

  $galleryImages.viewer({
    tooltip: 0,
    inline: false,
    toolbar: {
      zoomIn: {
        show: 1,
        size: 'large',
      },
      zoomOut: {
        show: 1,
        size: 'large',
      },
      oneToOne: 0,
      reset: 0,
      prev: {
        show: 1,
        size: 'large',
      },
      play: {
        show: 0,
        size: 'large',
      },
      next: {
        show: 1,
        size: 'large',
      },
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    },
    title: 0,
    viewed: function () {
      $galleryImages.viewer('zoomTo', 1);
    },
  });
}
// Implementation of Jquery Viewer

// Full Width Video Player
function fullWidthVideoPlayer() {
  for (let i = 1; i <= $('.playvideo').length ; i++) {
    let idName = 'play-video-' + i;
    let newIdName = 'video-iframe-' + i;
    $('.playvideo')[i-1].setAttribute('id', idName);
    $('.iframe-video')[i-1].setAttribute('id', newIdName);
    $('#'+idName).click(function(){
      $(this).parent('.video-wrp').addClass('video-playing');
      if ($('#'+newIdName).hasClass('video-element')) {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName).get(0).play();
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      } else {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName)[0].src += '&autoplay=1';
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      }
    });
  }
}
// Full Width Video Player

// Popup Video Player for Two Column Section
function videoPopupPlayer() {
  var $videoSrc;
  $('.video-iframe-thumbnail').click(function () {
    $videoSrc = $(this).data('src');
  });

  $('#myModal').on('shown.bs.modal', function () {
    $('#video').attr(
      'src',
      $videoSrc + '?autoplay=1&mute=1&amp;modestbranding=1&amp;showinfo=0'
    );
  });

  $('#myModal').on('hide.bs.modal', function () {
    $('#video').attr('src', $videoSrc);
  });
}
// Popup Video Player for Two Column Section

// Delay Home Banner Video
function delayHomeBannerVideo() {
  setTimeout(function() {
    $('#home-banner-iframe').attr('src',$('#hidden-iframe-url').text());
    $('#home-banner-video').attr('src',$('#hidden-video-file').text());
  }, 3000);
}
// Delay Home Banner Video

function footerTicker() {
  if ($('.footer .email-ticker-wrp')) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    const slideshowEl = document.querySelector('.footer .email-ticker-wrp');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    slideshowEl.addEventListener('mouseenter', pause, false);
    slideshowEl.addEventListener('focusin', pause, false);
    slideshowEl.addEventListener('mouseleave', play, false);
    slideshowEl.addEventListener('focusout', play, false);

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}

// Header Scroll up Option
function slideUpMenuOption() {
  if ($('body > .header').hasClass('enable-scroll-up')) {
    var lastScrollTop = 0, delta = 15;
    var bannerHeight = $('.header').outerHeight();
    $(window).scroll(function(){
      var st = $(this).scrollTop();

      if(Math.abs(lastScrollTop - st) <= delta)
        return;
      if ((st > lastScrollTop) && (lastScrollTop>0)) {
            // downscroll code
            $('body > .header, body > .sticky-overlay').css('top', (bannerHeight - (bannerHeight *2)));
      } else {
        // upscroll code
        $('body > .header, body > .sticky-overlay').css('top','0px');
      }
      lastScrollTop = st;
    });
  }
}
// Header Scroll up Option

function motorCalculator() {
  if ($('#motor-calculator-section.automotive-calculator input[name="motor-select-automotive"]:checked').val()) {
    // Get Selected Motor Details
    let motorRadio = $('#motor-calculator-section.automotive-calculator input[name="motor-select-automotive"]:checked').val();
    let motorValues = motorRadio.split('-');
    let motorId = parseFloat(motorValues[1]);
    let motorName = motorValues[2];

    $('#motor-calculator-section.automotive-calculator #form-motor-name').text(motorName);

    // Get Input Values and Check for Validation
    let frontalArea = parseFloat($('#motor-calculator-section.automotive-calculator .motor-calculation .car-frontal-area').val());
    if (frontalArea < 0) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .car-frontal-area').val('2.24');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .car-frontal-area').trigger('change');
    }
    let mass = parseFloat($('#motor-calculator-section.automotive-calculator .motor-calculation .mass-of-car').val());
    if (mass <= 0) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .mass-of-car').val('1635');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .mass-of-car').trigger('change');
    }
    let gearRatio = parseFloat($('#motor-calculator-section.automotive-calculator .motor-calculation .gear-ratio').val());
    if (gearRatio < 1) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .gear-ratio').val('3');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .gear-ratio').trigger('change');
    }
    let tyreRadius = parseFloat($('.motor-calculation .tyre-radius').val());
    if (tyreRadius < 0) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .tyre-radius').val('0.2515');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .tyre-radius').trigger('change');
    }
    let airDensity = parseFloat($('#motor-calculator-section.automotive-calculator .motor-calculation .air-density').val());
    if (airDensity < 0 || airDensity > 1.5) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .air-density').val('1.225');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .air-density').trigger('change');
    }
    let transmissionEfficiency = parseFloat($('#motor-calculator-section.automotive-calculator .motor-calculation .transmission-efficiency').val());
    if (transmissionEfficiency < 1 || transmissionEfficiency > 99) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .transmission-efficiency').val('96');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .transmission-efficiency').trigger('change');
    }
    let rollingResistance = parseFloat($('#motor-calculator-section.automotive-calculator .motor-calculation .rolling-resistance').val());
    if (rollingResistance < 0) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .rolling-resistance').val('0.0054');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .rolling-resistance').trigger('change');
    }
    let dragCoefficient = parseFloat($('#motor-calculator-section.automotive-calculator .motor-calculation .coefficient-of-drag').val());
    if (dragCoefficient < 0) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .coefficient-of-drag').val('0.24');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .coefficient-of-drag').trigger('change');
    }
    let angleOfSlope = parseFloat($('#motor-calculator-section.automotive-calculator .motor-calculation .angle-of-slope').val());
    if (angleOfSlope < -89 || angleOfSlope > 89) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .angle-of-slope').val('1');
      $('#motor-calculator-section.automotive-calculator .motor-calculation .angle-of-slope').trigger('change');
    }
    let gravity = 9.81;
    let effectiveMass = (mass*(1.04+0.0025*gearRatio*gearRatio)).toFixed(1);
    let myData = JSON.parse(eval(`jsonMotorDataAutomotive${motorId}`));

    // Set Motor Data to Contact Form
    $('#motor-calculator-section.automotive-calculator #form-selected-motor').val(motorName);
    $('#motor-calculator-section.automotive-calculator #form-selected-car-frontal-area').val(frontalArea);
    $('#motor-calculator-section.automotive-calculator #form-selected-gear-ratio').val(gearRatio);
    $('#motor-calculator-section.automotive-calculator #form-selected-mass').val(mass);
    $('#motor-calculator-section.automotive-calculator #form-selected-tyre-radius').val(tyreRadius);

    // Calculation of Acceleration
    const gettorque = getTorqueForRPM(0,myData);
    const getThrust = gettorque*transmissionEfficiency*0.01*gearRatio/tyreRadius;
    const getResistanceForces = (0.5*airDensity*frontalArea*dragCoefficient*0)+(rollingResistance*effectiveMass*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMass*gravity*Math.sin(angleOfSlope*Math.PI/180));
    const notChangingResistance = (rollingResistance*effectiveMass*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMass*gravity*Math.sin(angleOfSlope*Math.PI/180));
    const getAcceleration = (getThrust-getResistanceForces)/(effectiveMass);

    let maxSpeedFromRPM = tyreRadius*2*3.1416*Number(myData[myData.length-1][0])/(60*gearRatio);

    let previousVelocity= 0;
    let previousAcceleration= getAcceleration;
    let newVelocity=0.02;
    let newAcceleration;
    let countTo100 = 0;
    let countToEnd = 0;

    let dontBreakLoop = true;

    while (dontBreakLoop) {
      countToEnd++;
      if (newVelocity < 27.7778) {
        countTo100++;
      }

      if (previousVelocity+previousAcceleration*0.1 < maxSpeedFromRPM) {
        newVelocity = previousVelocity+previousAcceleration*0.1;
      } else {
        dontBreakLoop = false;
      }
      newAcceleration = (((getTorqueForRPM((60*newVelocity*gearRatio/(2*3.1416*tyreRadius)),myData))*transmissionEfficiency*0.01*gearRatio/tyreRadius)-(0.5*airDensity*frontalArea*dragCoefficient*newVelocity*newVelocity + notChangingResistance))/(effectiveMass);

      if (newAcceleration < 0.001 || countToEnd >= 3000 || newVelocity-previousVelocity<0.001) {
        dontBreakLoop = false;
      }

      previousVelocity = newVelocity;
      previousAcceleration = newAcceleration;
    }

    if (newVelocity >= 27.778) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .vehicle-acceleration').text(`${((countTo100-1)/10).toFixed(1)} sec`);
    } else {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .vehicle-acceleration').text('Cannot Reach 100km/h');
    }

    if (isNaN(newVelocity)) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .max-speed').text(`${parseInt(0)} mph`);
    } else if (maxSpeedFromRPM <= newVelocity) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .max-speed').text(`${parseInt(maxSpeedFromRPM*2.236936)} mph`);
    } else if (maxSpeedFromRPM > newVelocity) {
      $('#motor-calculator-section.automotive-calculator .motor-calculation .max-speed').text(`${parseInt(newVelocity*2.236936)} mph`);
    }
  }

  if ($('#motor-calculator-section.heavy-vehicle-calculator input[name="motor-select-heavy-vehicle"]:checked').val()) {
    // Get Selected Motor Details
    let motorRadio = $('#motor-calculator-section.heavy-vehicle-calculator input[name="motor-select-heavy-vehicle"]:checked').val();
    let motorValues = motorRadio.split('-');
    let motorId = parseFloat(motorValues[1]);
    let motorName = motorValues[2];

    $('#motor-calculator-section.heavy-vehicle-calculator #form-motor-name').text(motorName);

    // Get Input Values and Check for Validation
    let frontalArea = parseFloat($('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .car-frontal-area').val());
    if (frontalArea < 0) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .car-frontal-area').val('2.24');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .car-frontal-area').trigger('change');
    }
    let mass = parseFloat($('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .mass-of-car').val());
    if (mass <= 0) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .mass-of-car').val('1635');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .mass-of-car').trigger('change');
    }
    let gearRatio = parseFloat($('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .gear-ratio').val());
    if (gearRatio < 1) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .gear-ratio').val('3');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .gear-ratio').trigger('change');
    }
    let tyreRadius = parseFloat($('.motor-calculation .tyre-radius').val());
    if (tyreRadius < 0) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .tyre-radius').val('0.2515');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .tyre-radius').trigger('change');
    }
    let airDensity = parseFloat($('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .air-density').val());
    if (airDensity < 0 || airDensity > 1.5) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .air-density').val('1.225');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .air-density').trigger('change');
    }
    let transmissionEfficiency = parseFloat($('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .transmission-efficiency').val());
    if (transmissionEfficiency < 1 || transmissionEfficiency > 99) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .transmission-efficiency').val('96');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .transmission-efficiency').trigger('change');
    }
    let rollingResistance = parseFloat($('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .rolling-resistance').val());
    if (rollingResistance < 0) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .rolling-resistance').val('0.0054');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .rolling-resistance').trigger('change');
    }
    let dragCoefficient = parseFloat($('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .coefficient-of-drag').val());
    if (dragCoefficient < 0) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .coefficient-of-drag').val('0.24');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .coefficient-of-drag').trigger('change');
    }
    let angleOfSlope = parseFloat($('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .angle-of-slope').val());
    if (angleOfSlope < -89 || angleOfSlope > 89) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .angle-of-slope').val('1');
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .angle-of-slope').trigger('change');
    }
    let gravity = 9.81;
    let effectiveMass = (mass*(1.04+0.0025*gearRatio*gearRatio)).toFixed(1);
    let myData = JSON.parse(eval(`jsonMotorDataHeavyVehicle${motorId}`));

    // Set Motor Data to Contact Form
    $('#motor-calculator-section.heavy-vehicle-calculator #form-selected-motor').val(motorName);
    $('#motor-calculator-section.heavy-vehicle-calculator #form-selected-car-frontal-area').val(frontalArea);
    $('#motor-calculator-section.heavy-vehicle-calculator #form-selected-gear-ratio').val(gearRatio);
    $('#motor-calculator-section.heavy-vehicle-calculator #form-selected-mass').val(mass);
    $('#motor-calculator-section.heavy-vehicle-calculator #form-selected-tyre-radius').val(tyreRadius);

    // Calculation of Acceleration
    const gettorque = getTorqueForRPM(0,myData);
    const getThrust = gettorque*transmissionEfficiency*0.01*gearRatio/tyreRadius;
    const getResistanceForces = (0.5*airDensity*frontalArea*dragCoefficient*0)+(rollingResistance*effectiveMass*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMass*gravity*Math.sin(angleOfSlope*Math.PI/180));
    const notChangingResistance = (rollingResistance*effectiveMass*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMass*gravity*Math.sin(angleOfSlope*Math.PI/180));
    const getAcceleration = (getThrust-getResistanceForces)/(effectiveMass);

    let maxSpeedFromRPM = tyreRadius*2*3.1416*Number(myData[myData.length-1][0])/(60*gearRatio);

    let previousVelocity= 0;
    let previousAcceleration= getAcceleration;
    let newVelocity=0.02;
    let newAcceleration;
    let countTo100 = 0;
    let countToEnd = 0;

    let dontBreakLoop = true;

    while (dontBreakLoop) {
      countToEnd++;
      if (newVelocity < 27.7778) {
        countTo100++;
      }

      if (previousVelocity+previousAcceleration*0.1 < maxSpeedFromRPM) {
        newVelocity = previousVelocity+previousAcceleration*0.1;
      } else {
        dontBreakLoop = false;
      }
      newAcceleration = (((getTorqueForRPM((60*newVelocity*gearRatio/(2*3.1416*tyreRadius)),myData))*transmissionEfficiency*0.01*gearRatio/tyreRadius)-(0.5*airDensity*frontalArea*dragCoefficient*newVelocity*newVelocity + notChangingResistance))/(effectiveMass);

      if (newAcceleration < 0.001 || countToEnd >= 3000 || newVelocity-previousVelocity<0.001) {
        dontBreakLoop = false;
      }

      previousVelocity = newVelocity;
      previousAcceleration = newAcceleration;
    }

    if (newVelocity >= 27.778) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .vehicle-acceleration').text(`${((countTo100-1)/10).toFixed(1)} sec`);
    } else {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .vehicle-acceleration').text('Cannot Reach 100km/h');
    }

    if (isNaN(newVelocity)) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .max-speed').text(`${parseInt(0)} mph`);
    } else if (maxSpeedFromRPM <= newVelocity) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .max-speed').text(`${parseInt(maxSpeedFromRPM*2.236936)} mph`);
    } else if (maxSpeedFromRPM > newVelocity) {
      $('#motor-calculator-section.heavy-vehicle-calculator .motor-calculation .max-speed').text(`${parseInt(newVelocity*2.236936)} mph`);
    }
  }
}

function motorListingCalculator(motorSectionWrp) {
  // Get Selected Motor Details
  let motorData = motorSectionWrp.attr('data-motor');
  let motorValues = motorData.split('-');
  let motorId = parseFloat(motorValues[1]);

  // Get Input Values and Check for Validation
  let frontalArea = parseFloat(motorSectionWrp.find('.car-frontal-area').val());
  if (frontalArea < 0) {
    motorSectionWrp.find('.car-frontal-area').val('2.24');
    motorSectionWrp.find('.car-frontal-area').trigger('change');
  }
  let mass = parseFloat(motorSectionWrp.find('.mass-of-car').val());
  if (mass <= 0) {
    motorSectionWrp.find('.mass-of-car').val('1635');
    motorSectionWrp.find('.mass-of-car').trigger('change');
  }
  let gearRatio = parseFloat(motorSectionWrp.find('.gear-ratio').val());
  if (gearRatio < 1) {
    motorSectionWrp.find('.gear-ratio').val('3');
    motorSectionWrp.find('.gear-ratio').trigger('change');
  }
  let tyreRadius = parseFloat(motorSectionWrp.find('.tyre-radius').val());
  if (tyreRadius < 0.0001) {
    motorSectionWrp.find('.tyre-radius').val('0.2515');
    motorSectionWrp.find('.tyre-radius').trigger('change');
  }

  let airDensity = parseFloat(motorSectionWrp.find('.air-density').val());
  if (airDensity < 0.4 || airDensity > 1.5) {
    motorSectionWrp.find('.air-density').val('1.225');
    motorSectionWrp.find('.air-density').trigger('change');
  }
  let transmissionEfficiency = parseFloat(motorSectionWrp.find('.transmission-efficiency').val());
  if (transmissionEfficiency < 1 || transmissionEfficiency > 99) {
    motorSectionWrp.find('.transmission-efficiency').val('96');
    motorSectionWrp.find('.transmission-efficiency').trigger('change');
  }
  let rollingResistance = parseFloat(motorSectionWrp.find('.rolling-resistance').val());
  if (rollingResistance < 0.0001) {
    motorSectionWrp.find('.rolling-resistance').val('0.0054');
    motorSectionWrp.find('.rolling-resistance').trigger('change');
  }
  let dragCoefficient = parseFloat(motorSectionWrp.find('.coefficient-of-drag').val());
  if (dragCoefficient < 0.01) {
    motorSectionWrp.find('.coefficient-of-drag').val('0.24');
    motorSectionWrp.find('.coefficient-of-drag').trigger('change');
  }
  let angleOfSlope = parseFloat(motorSectionWrp.find('.angle-of-slope').val());
  if (angleOfSlope < -89 || angleOfSlope > 89) {
    motorSectionWrp.find('.angle-of-slope').val('1');
    motorSectionWrp.find('.angle-of-slope').trigger('change');
  }

  let gravity = 9.81;
  let effectiveMassListing = (mass*(1.04+0.0025*gearRatio*gearRatio)).toFixed(1);
  let myDataListing = JSON.parse(eval(`jsonMotorDataListing${motorId}`));

  // Calculation of Acceleration
  const gettorque = getTorqueForRPM(0,myDataListing);
  const getThrust = gettorque*transmissionEfficiency*0.01*gearRatio/tyreRadius;
  const getResistanceForces = (0.5*airDensity*frontalArea*dragCoefficient*0)+(rollingResistance*effectiveMassListing*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMassListing*gravity*Math.sin(angleOfSlope*Math.PI/180));
  const notChangingResistance = (rollingResistance*effectiveMassListing*gravity*Math.cos(angleOfSlope*Math.PI/180))+(effectiveMassListing*gravity*Math.sin(angleOfSlope*Math.PI/180));
  const getAcceleration = (getThrust-getResistanceForces)/(effectiveMassListing);

  let maxSpeedFromRPM = tyreRadius*2*3.1416*Number(myDataListing[myDataListing.length-1][0])/(60*gearRatio);

  let previousVelocity= 0;
  let previousAcceleration= getAcceleration;
  let newVelocity=0.02;
  let newAcceleration;
  let countTo100 = 0;
  let countToEnd = 0;

  let dontBreakLoop = true;

  while (dontBreakLoop) {
    countToEnd++;
    if (newVelocity < 27.7778) {
      countTo100++;
    }

    if (previousVelocity+previousAcceleration*0.1 < maxSpeedFromRPM) {
      newVelocity = previousVelocity+previousAcceleration*0.1;
    } else {
      dontBreakLoop = false;
    }
    newAcceleration = (((getTorqueForRPM((60*newVelocity*gearRatio/(2*3.1416*tyreRadius)),myDataListing))*transmissionEfficiency*0.01*gearRatio/tyreRadius)-(0.5*airDensity*frontalArea*dragCoefficient*newVelocity*newVelocity + notChangingResistance))/(effectiveMassListing);

    if (newAcceleration < 0.001 || countToEnd >= 3000 || newVelocity-previousVelocity<0.001) {
      dontBreakLoop = false;
    }

    previousVelocity = newVelocity;
    previousAcceleration = newAcceleration;
  }

  if (newVelocity >= 27.778) {
    motorSectionWrp.find('.vehicle-acceleration').text(`${((countTo100-1)/10).toFixed(1)} sec`);
  } else {
    motorSectionWrp.find('.vehicle-acceleration').text('Cannot Reach 100km/h');
  }

  if (isNaN(newVelocity)) {
    motorSectionWrp.find('.max-speed').text(`${parseInt(0)} mph`);
  } else if (maxSpeedFromRPM <= newVelocity) {
    motorSectionWrp.find('.max-speed').text(`${parseInt(maxSpeedFromRPM*2.236936)} mph`);
  } else if (maxSpeedFromRPM > newVelocity) {
    motorSectionWrp.find('.max-speed').text(`${parseInt(newVelocity*2.236936)} mph`);
  }
}

function checkSectionsForGaps() {
  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1, .fcp-section.video-section.v1, .fcp-section.cta-section').each(function () {
    if($(this).next().hasClass('bg-enabled')) {
      $(this).addClass('bg-next');
    }
  });

  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1, .fcp-section.video-section.v1').each(function () {
    if($(this).next().hasClass('cta-section')) {
      $(this).addClass('cta-next');
    }
  });
}

function getTorqueForRPM(rpm,jsonMotorData) {
  let roundRPM = Math.round(rpm/100)*100;
  for (let i = 0; i < jsonMotorData.length; i++) {
    if (Number(jsonMotorData[i][0]) == roundRPM) {
      return Number(jsonMotorData[i][1])
    }
  }
}

function checkMapWidth() {
  let addressWrapperWidth = $('#map-address-wrp').width();
  let mapWrapperWidth = $('.map-wrp').width();

  if (mapWrapperWidth < addressWrapperWidth) {
    return true;
  } else {
    return false;
  }
}

// Cookie Policy Popup
function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
// Cookie Policy Popup

// New Calculator
function newCalculator() {
  // Calculations on Initial Load
  let selectedCalculation = $('#new-motor-calculator .select-calculation').val();
  $('#new-motor-calculator .calculator-body-wrp .calculator-item').each(function () {
    $(this).slideUp();
  });
  $(`#new-motor-calculator .calculator-body-wrp .calculator-item.${selectedCalculation}`).slideDown();
  // Calculations on Initial Load

  // Calculations on Change
  $('#new-motor-calculator .select-calculation').on('change', function() {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item').each(function () {
      $(this).slideUp();
    });
    $(`#new-motor-calculator .calculator-body-wrp .calculator-item.${this.value}`).slideDown();
  });

  $('#reset-button-hydraulic-calculator').click(function (e) {
    e.preventDefault();
    $('.calculator-item input').each(function() {
      $(this).val('');
      $(this).prop('disabled',false);
    });

    $('#new-motor-calculator .calculator-item .calculator-row .final-answer').each(function() {
      $(this).text('');
    });

  });

  $('#new-motor-calculator select.calculator-units-change').on('change', function() {
    speedCalculator();
    flowRateCalculator();
    pressureCalculator();
    displacementCalculator();
    torqueCalculator();
    powerCalculator();
  });

  $('#new-motor-calculator .calculator-units-change.metric').parent().removeClass('d-none');

  $('#new-motor-calculator  input[type=radio][name=base-units-change-radio]').change(function() {
    if (this.value == 'metric') {
      $('#new-motor-calculator .calculator-units-change.metric').each(function() {
        $(this).removeClass('d-none');
        $(this).parent().removeClass('d-none');
        $(this).parent().siblings('.select2-calculator-wrapper').children('select.imperial').addClass('d-none');
        $(this).parent().siblings('.select2-calculator-wrapper').addClass('d-none');
        // $(this).siblings('.imperial').parent().siblings().addClass('d-none');
      });
    }
    else if (this.value == 'imperial') {
      $('#new-motor-calculator .calculator-units-change.imperial').each(function() {
        $(this).removeClass('d-none');
        $(this).parent().removeClass('d-none');
        $(this).parent().siblings('.select2-calculator-wrapper').children('select.metric').addClass('d-none');
        $(this).parent().siblings('.select2-calculator-wrapper').addClass('d-none');
        // $(this).siblings('.metric').parent().siblings().addClass('d-none');
      });
    }

    flowRateCalculator();
    speedCalculator();
    pressureCalculator();
    displacementCalculator();
    torqueCalculator();
    powerCalculator();
  });

  speedCalculator();
  flowRateCalculator();
  pressureCalculator();
  displacementCalculator();
  torqueCalculator();
  powerCalculator();
  // Calculations on Change
}

function speedCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').parent().parent().addClass('disabled-field');

      speedCalc1();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').parent().parent().removeClass('disabled-field');
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').parent().parent().addClass('disabled-field');

      speedCalc2();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').parent().parent().removeClass('disabled-field');
    }
  });

  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val()) {
    speedCalc1();
  }
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val()) {
    speedCalc2();
  }
}

function speedCalc1() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val()) {
    let torqueValue;
    if ($('#new-motor-calculator .speed-calculator-torque-units:not(.d-none) option:selected').val() == 'newtnm') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val();
    } else if ($('#new-motor-calculator .speed-calculator-torque-units:not(.d-none) option:selected').val() == 'knewtnm') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val()*1000;
    } else if ($('#new-motor-calculator .speed-calculator-torque-units:not(.d-none) option:selected').val() == 'lbfin') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val()/8.850745454;
    } else if ($('#new-motor-calculator .speed-calculator-torque-units:not(.d-none) option:selected').val() == 'lbfft') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#torque').val()/0.7375621212;
    }
    let powerValue;
    if ($('#new-motor-calculator .speed-calculator-power-units:not(.d-none) option:selected').val() == 'watt') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val();
    } else if ($('#new-motor-calculator .speed-calculator-power-units:not(.d-none) option:selected').val() == 'kwatt') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val()*1000;
    } else if ($('#new-motor-calculator .speed-calculator-power-units:not(.d-none) option:selected').val() == 'hp') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val()*745.7;
    }

    let answer;
    if ($('#new-motor-calculator .speed-calculator-answer-units:not(.d-none) option:selected').val() == 'rps') {
      answer = (powerValue/torqueValue)/(2*Math.PI);
    } else if ($('#new-motor-calculator .speed-calculator-answer-units:not(.d-none) option:selected').val() == 'rads') {
      answer = (powerValue*6.2832/torqueValue)/(2*Math.PI);
    } else if ($('#new-motor-calculator .speed-calculator-answer-units:not(.d-none) option:selected').val() == 'rpm') {
      answer = (powerValue*60/torqueValue)/(2*Math.PI);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator #answer-speed').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator #answer-speed').text('');
  }
}

function speedCalc2() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val()) {
    let flowRate;
    if ($('#new-motor-calculator .speed-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cmps') {
      flowRate = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val();
    } else if ($('#new-motor-calculator .speed-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cmpmin') {
      flowRate = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val()/60;
    } else if ($('#new-motor-calculator .speed-calculator-flowrate-units:not(.d-none) option:selected').val() == 'lpm') {
      flowRate = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val()/60000;
    } else if ($('#new-motor-calculator .speed-calculator-flowrate-units:not(.d-none) option:selected').val() == 'gpm') {
      flowRate = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val()/15850.3;
    } else if ($('#new-motor-calculator .speed-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cfm') {
      flowRate = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val()/2118.88;
    } else if ($('#new-motor-calculator .speed-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cfs') {
      flowRate = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#flow-rate').val()/35.315;
    }
    let volumetricEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#volumetric-efficiency').val();
    let displacement;
    if ($('#new-motor-calculator .speed-calculator-displacement-units:not(.d-none) option:selected').val() == 'cmprev') {
      displacement = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val();
    } else if ($('#new-motor-calculator .speed-calculator-displacement-units:not(.d-none) option:selected').val() == 'ccmprev') {
      displacement = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val()/1000000;
    } else if ($('#new-motor-calculator .speed-calculator-displacement-units:not(.d-none) option:selected').val() == 'cinprev') {
      displacement = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val()/61023.7;
    } else if ($('#new-motor-calculator .speed-calculator-displacement-units:not(.d-none) option:selected').val() == 'cftprev') {
      displacement = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#displacement').val()/35.3147;
    }
    let answer;
    if ($('#new-motor-calculator .speed-calculator-answer-units:not(.d-none) option:selected').val() == 'rps') {
      answer = (flowRate*volumetricEfficiency/100)/displacement;
    } else if ($('#new-motor-calculator .speed-calculator-answer-units:not(.d-none) option:selected').val() == 'rads') {
      answer = (flowRate*volumetricEfficiency*6.2831/100)/displacement;
    } else if ($('#new-motor-calculator .speed-calculator-answer-units:not(.d-none) option:selected').val() == 'rpm') {
      answer = (flowRate*volumetricEfficiency*60/100)/displacement;
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator #answer-speed').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator #answer-speed').text('');
  }
}

function flowRateCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#mechanical-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#mechanical-efficiency').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').parent().parent().addClass('disabled-field');

      flowRateCalc1();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#mechanical-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#mechanical-efficiency').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').parent().parent().removeClass('disabled-field');
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#mechanical-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').parent().parent().addClass('disabled-field');

      flowRateCalc2();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').parent().parent().removeClass('disabled-field');
    }
  });

  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#volumetric-efficiency').val()) {
    flowRateCalc1();
  }
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#volumetric-efficiency').val()) {
    flowRateCalc2();
  }
}

function flowRateCalc1() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#volumetric-efficiency').val()) {
    let displacementValue;
    if ($('#new-motor-calculator .flowrate-calculator-displacement-units:not(.d-none) option:selected').val() == 'cmprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').val();
    } else if ($('#new-motor-calculator .flowrate-calculator-displacement-units:not(.d-none) option:selected').val() == 'ccmprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').val()/1000000;
    } else if ($('#new-motor-calculator .flowrate-calculator-displacement-units:not(.d-none) option:selected').val() == 'cinprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').val()/61023.7;
    } else if ($('#new-motor-calculator .flowrate-calculator-displacement-units:not(.d-none) option:selected').val() == 'cftprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#displacement').val()/35.3147;
    }

    let speedValue;
    if ($('#new-motor-calculator .flowrate-calculator-speed-units:not(.d-none) option:selected').val() == 'rps') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').val();
    } else if ($('#new-motor-calculator .flowrate-calculator-speed-units:not(.d-none) option:selected').val() == 'rads') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').val()/6.2832;
    } else if ($('#new-motor-calculator .flowrate-calculator-speed-units:not(.d-none) option:selected').val() == 'rpm') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#speed').val()/60;
    }

    let volumetricEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#volumetric-efficiency').val();

    let answer;
    if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'cmps') {
      answer = displacementValue*speedValue*100/volumetricEfficiency;
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'cmpmin') {
      answer = displacementValue*speedValue*100*60/volumetricEfficiency;
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'lpm') {
      answer = displacementValue*speedValue*100*60*1000/volumetricEfficiency;
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'gpm') {
      answer = displacementValue*speedValue*100*15850.3/volumetricEfficiency;
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'cfm') {
      answer = displacementValue*speedValue*100*2118.88/volumetricEfficiency;
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'cfs') {
      answer = displacementValue*speedValue*100*35.315/volumetricEfficiency;
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator #answer-flowrate').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator #answer-flowrate').text('');
  }
}

function flowRateCalc2() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#volumetric-efficiency').val()) {

    let powerValue;
    if ($('#new-motor-calculator .flowrate-calculator-power-units:not(.d-none) option:selected').val() == 'watt') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').val();
    } else if ($('#new-motor-calculator .flowrate-calculator-power-units:not(.d-none) option:selected').val() == 'kwatt') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').val()*1000;
    } else if ($('#new-motor-calculator .flowrate-calculator-power-units:not(.d-none) option:selected').val() == 'hp') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#power').val()*745.7;
    }

    let pressureValue;
    if ($('#new-motor-calculator .flowrate-calculator-pressure-units:not(.d-none) option:selected').val() == 'pascal') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val();
    } else if ($('#new-motor-calculator .flowrate-calculator-pressure-units:not(.d-none) option:selected').val() == 'kpascal') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val()*1000;
    } else if ($('#new-motor-calculator .flowrate-calculator-pressure-units:not(.d-none) option:selected').val() == 'bar') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val()*100000;
    }  else if ($('#new-motor-calculator .flowrate-calculator-pressure-units:not(.d-none) option:selected').val() == 'psi') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val()*6894.76;
    }  else if ($('#new-motor-calculator .flowrate-calculator-pressure-units:not(.d-none) option:selected').val() == 'lbftsqr') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val()*47.88;
    }  else if ($('#new-motor-calculator .flowrate-calculator-pressure-units:not(.d-none) option:selected').val() == 'atm') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#pressure').val()*101325;
    }

    let volumetricEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#volumetric-efficiency').val();
    let mechanicalEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator input#mechanical-efficiency').val();

    let answer;
    if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'cmps') {
      answer = powerValue*10000/(volumetricEfficiency*mechanicalEfficiency*pressureValue);
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'cmpmin') {
      answer = powerValue*10000*60/(volumetricEfficiency*mechanicalEfficiency*pressureValue);
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'lpm') {
      answer = powerValue*10000*60*1000/(volumetricEfficiency*mechanicalEfficiency*pressureValue);
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'gpm') {
      answer = powerValue*10000*15850.3/(volumetricEfficiency*mechanicalEfficiency*pressureValue);
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'cfm') {
      answer = powerValue*10000*2118.88/(volumetricEfficiency*mechanicalEfficiency*pressureValue);
    } else if ($('#new-motor-calculator .flowrate-calculator-answer-units:not(.d-none) option:selected').val() == 'cfs') {
      answer = powerValue*10000*35.315/(volumetricEfficiency*mechanicalEfficiency*pressureValue);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator #answer-flowrate').text(answer.toFixed(3));

  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.flowrate-calculator #answer-flowrate').text('');
  }
}

function pressureCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#volumetric-efficiency').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').parent().parent().addClass('disabled-field');

      pressureCalc1();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').parent().parent().removeClass('disabled-field');
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#volumetric-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#volumetric-efficiency').parent().parent().addClass('disabled-field');

      pressureCalc2();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#volumetric-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#volumetric-efficiency').parent().parent().removeClass('disabled-field');
    }
  });

  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#mechanical-efficiency').val()) {
    pressureCalc1();
  }
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#mechanical-efficiency').val()) {
    pressureCalc2();
  }
}

function pressureCalc1() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#mechanical-efficiency').val()) {
    let powerValue;
    if ($('#new-motor-calculator .pressure-calculator-power-units:not(.d-none) option:selected').val() == 'watt') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').val();
    } else if ($('#new-motor-calculator .pressure-calculator-power-units:not(.d-none) option:selected').val() == 'kwatt') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').val()*1000;
    } else if ($('#new-motor-calculator .pressure-calculator-power-units:not(.d-none) option:selected').val() == 'hp') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#power').val()*745.7;
    }

    let flowRateValue;
    if ($('#new-motor-calculator .pressure-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cmps') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val();
    } else if ($('#new-motor-calculator .pressure-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cmpmin') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val()/60;
    } else if ($('#new-motor-calculator .pressure-calculator-flowrate-units:not(.d-none) option:selected').val() == 'lpm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val()/60000;
    } else if ($('#new-motor-calculator .pressure-calculator-flowrate-units:not(.d-none) option:selected').val() == 'gpm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val()/15850.3;
    } else if ($('#new-motor-calculator .pressure-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cfm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val()/2118.88;
    } else if ($('#new-motor-calculator .pressure-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cfs') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#flowrate').val()/35.315;
    }

    let volumetricEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#volumetric-efficiency').val();
    let mechanicalEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#mechanical-efficiency').val();

    let answer;
    if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'pascal') {
      answer = powerValue*10000/(volumetricEfficiency*mechanicalEfficiency*flowRateValue);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'kpascal') {
      answer = powerValue*10000/(volumetricEfficiency*mechanicalEfficiency*flowRateValue*1000);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'bar') {
      answer = powerValue*10000/(volumetricEfficiency*mechanicalEfficiency*flowRateValue*100000);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'psi') {
      answer = powerValue*10000/(volumetricEfficiency*mechanicalEfficiency*flowRateValue*6894.76);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'lbftsqr') {
      answer = powerValue*10000/(volumetricEfficiency*mechanicalEfficiency*flowRateValue*47.88);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'atm') {
      answer = powerValue*10000/(volumetricEfficiency*mechanicalEfficiency*flowRateValue);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator #answer-pressure').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator #answer-pressure').text('');
  }
}

function pressureCalc2() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#mechanical-efficiency').val()) {
    let torqueValue;
    if ($('#new-motor-calculator .pressure-calculator-torque-units:not(.d-none) option:selected').val() == 'newtnm') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').val();
    } else if ($('#new-motor-calculator .pressure-calculator-torque-units:not(.d-none) option:selected').val() == 'knewtnm') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').val()*1000;
    } else if ($('#new-motor-calculator .pressure-calculator-torque-units:not(.d-none) option:selected').val() == 'lbfin') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').val()/8.850745454;
    } else if ($('#new-motor-calculator .pressure-calculator-torque-units:not(.d-none) option:selected').val() == 'lbfft') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#torque').val()/0.7375621212;
    }

    let displacementValue;
    if ($('#new-motor-calculator .pressure-calculator-displacement-units:not(.d-none) option:selected').val() == 'cmprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').val();
    } else if ($('#new-motor-calculator .pressure-calculator-displacement-units:not(.d-none) option:selected').val() == 'ccmprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').val()/1000000;
    } else if ($('#new-motor-calculator .pressure-calculator-displacement-units:not(.d-none) option:selected').val() == 'cinprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').val()/61023.7;
    } else if ($('#new-motor-calculator .pressure-calculator-displacement-units:not(.d-none) option:selected').val() == 'cftprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#displacement').val()/35.3147;
    }

    let mechanicalEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator input#mechanical-efficiency').val();

    let answer;
    if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'pascal') {
      answer = torqueValue*2*Math.PI*100/(mechanicalEfficiency*displacementValue);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'kpascal') {
      answer = torqueValue*2*Math.PI*100/(mechanicalEfficiency*displacementValue*1000);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'bar') {
      answer = torqueValue*2*Math.PI*100/(mechanicalEfficiency*displacementValue*100000);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'psi') {
      answer = torqueValue*2*Math.PI*100/(mechanicalEfficiency*displacementValue*6894.76);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'lbftsqr') {
      answer = torqueValue*2*Math.PI*100/(mechanicalEfficiency*displacementValue*47.88);
    } else if ($('#new-motor-calculator .pressure-calculator-answer-units:not(.d-none) option:selected').val() == 'atm') {
      answer = torqueValue*2*Math.PI*100/(mechanicalEfficiency*displacementValue*101325);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator #answer-pressure').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.pressure-calculator #answer-pressure').text('');
  }
}

function displacementCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#mechanical-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#volumetric-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#volumetric-efficiency').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').parent().parent().addClass('disabled-field');

      displacementCalc1();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#volumetric-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#volumetric-efficiency').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').parent().parent().removeClass('disabled-field');
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#volumetric-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#mechanical-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#mechanical-efficiency').parent().parent().addClass('disabled-field');

      displacementCalc2();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#mechanical-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#mechanical-efficiency').parent().parent().removeClass('disabled-field');
    }
  });

  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val()) {
    displacementCalc1();
  }

  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').val()) {
    displacementCalc2();
  }
}

function displacementCalc1() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val()) {
    let torqueValue;
    if ($('#new-motor-calculator .displacement-calculator-torque-units:not(.d-none) option:selected').val() == 'newtnm') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').val();
    } else if ($('#new-motor-calculator .displacement-calculator-torque-units:not(.d-none) option:selected').val() == 'knewtnm') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').val()*1000;
    } else if ($('#new-motor-calculator .displacement-calculator-torque-units:not(.d-none) option:selected').val() == 'lbfin') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').val()/8.850745454;
    } else if ($('#new-motor-calculator .displacement-calculator-torque-units:not(.d-none) option:selected').val() == 'lbfft') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#torque').val()/0.7375621212;
    }

    let mechanicalEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#mechanical-efficiency').val();

    let pressureValue;
    if ($('#new-motor-calculator .displacement-calculator-pressure-units:not(.d-none) option:selected').val() == 'pascal') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val();
    } else if ($('#new-motor-calculator .displacement-calculator-pressure-units:not(.d-none) option:selected').val() == 'kpascal') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val()*1000;
    } else if ($('#new-motor-calculator .displacement-calculator-pressure-units:not(.d-none) option:selected').val() == 'bar') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val()*100000;
    }  else if ($('#new-motor-calculator .displacement-calculator-pressure-units:not(.d-none) option:selected').val() == 'psi') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val()*6894.76;
    }  else if ($('#new-motor-calculator .displacement-calculator-pressure-units:not(.d-none) option:selected').val() == 'lbftsqr') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val()*47.88;
    }  else if ($('#new-motor-calculator .displacement-calculator-pressure-units:not(.d-none) option:selected').val() == 'atm') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#pressure').val()*101325;
    }

    let answer;
    if ($('#new-motor-calculator .displacement-calculator-answer-units:not(.d-none) option:selected').val() == 'cmprev') {
      answer = torqueValue*Math.PI*200/(mechanicalEfficiency*pressureValue);
    } else if ($('#new-motor-calculator .displacement-calculator-answer-units:not(.d-none) option:selected').val() == 'ccmprev') {
      answer = torqueValue*Math.PI*200*1000000/(mechanicalEfficiency*pressureValue);
    } else if ($('#new-motor-calculator .displacement-calculator-answer-units:not(.d-none) option:selected').val() == 'cinprev') {
      answer = torqueValue*Math.PI*200*61023.7/(mechanicalEfficiency*pressureValue);
    } else if ($('#new-motor-calculator .displacement-calculator-answer-units:not(.d-none) option:selected').val() == 'cftprev') {
      answer = torqueValue*Math.PI*200*35.3147/(mechanicalEfficiency*pressureValue);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator #answer-displacement').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator #answer-displacement').text('');
  }
}

function displacementCalc2() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').val()) {
    let flowRateValue;
    if ($('#new-motor-calculator .displacement-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cmps') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val();
    } else if ($('#new-motor-calculator .displacement-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cmpmin') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val()/60;
    } else if ($('#new-motor-calculator .displacement-calculator-flowrate-units:not(.d-none) option:selected').val() == 'lpm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val()/60000;
    } else if ($('#new-motor-calculator .displacement-calculator-flowrate-units:not(.d-none) option:selected').val() == 'gpm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val()/15850.3;
    } else if ($('#new-motor-calculator .displacement-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cfm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val()/2118.88;
    } else if ($('#new-motor-calculator .displacement-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cfs') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#flowrate').val()/35.315;
    }

    let volumetricEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#volumetric-efficiency').val();

    let speedValue;
    if ($('#new-motor-calculator .displacement-calculator-speed-units:not(.d-none) option:selected').val() == 'rps') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').val();
    } else if ($('#new-motor-calculator .displacement-calculator-speed-units:not(.d-none) option:selected').val() == 'rads') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').val()/6.2832;
    } else if ($('#new-motor-calculator .displacement-calculator-speed-units:not(.d-none) option:selected').val() == 'rpm') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator input#speed').val()/60;
    }

    let answer;
    if ($('#new-motor-calculator .displacement-calculator-answer-units:not(.d-none) option:selected').val() == 'cmprev') {
      answer = flowRateValue*volumetricEfficiency/(speedValue*100);
    } else if ($('#new-motor-calculator .displacement-calculator-answer-units:not(.d-none) option:selected').val() == 'ccmprev') {
      answer = flowRateValue*volumetricEfficiency*1000000/(speedValue*100);
    } else if ($('#new-motor-calculator .displacement-calculator-answer-units:not(.d-none) option:selected').val() == 'cinprev') {
      answer = flowRateValue*volumetricEfficiency*61023.7/(speedValue*100);
    } else if ($('#new-motor-calculator .displacement-calculator-answer-units:not(.d-none) option:selected').val() == 'cftprev') {
      answer = flowRateValue*volumetricEfficiency*35.3147/(speedValue*100);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator #answer-displacement').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.displacement-calculator #answer-displacement').text('');
  }
}

function torqueCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').parent().parent().addClass('disabled-field');

      torqueCalc1();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').parent().parent().removeClass('disabled-field');
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').parent().parent().addClass('disabled-field');

      torqueCalc2();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').parent().parent().removeClass('disabled-field');
    }
  });

  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val()) {
    torqueCalc1();
  }
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()) {
    torqueCalc2();
  }
}

function torqueCalc2() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()) {

    let mechanicalEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#mechanical-efficiency').val();

    let displacementValue;
    if ($('#new-motor-calculator .torque-calculator-displacement-units:not(.d-none) option:selected').val() == 'cmprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val();
    } else if ($('#new-motor-calculator .torque-calculator-displacement-units:not(.d-none) option:selected').val() == 'ccmprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val()/1000000;
    } else if ($('#new-motor-calculator .torque-calculator-displacement-units:not(.d-none) option:selected').val() == 'cinprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val()/61023.7;
    } else if ($('#new-motor-calculator .torque-calculator-displacement-units:not(.d-none) option:selected').val() == 'cftprev') {
      displacementValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#displacement').val()/35.3147;
    }

    let pressureValue;
    if ($('#new-motor-calculator .torque-calculator-pressure-units:not(.d-none) option:selected').val() == 'pascal') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val();
    } else if ($('#new-motor-calculator .torque-calculator-pressure-units:not(.d-none) option:selected').val() == 'kpascal') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()*1000;
    } else if ($('#new-motor-calculator .torque-calculator-pressure-units:not(.d-none) option:selected').val() == 'bar') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()*100000;
    }  else if ($('#new-motor-calculator .torque-calculator-pressure-units:not(.d-none) option:selected').val() == 'psi') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()*6894.76;
    }  else if ($('#new-motor-calculator .torque-calculator-pressure-units:not(.d-none) option:selected').val() == 'lbftsqr') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()*47.88;
    }  else if ($('#new-motor-calculator .torque-calculator-pressure-units:not(.d-none) option:selected').val() == 'atm') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#pressure').val()*101325;
    }

    let answer;
    if ($('#new-motor-calculator .torque-calculator-answer-units:not(.d-none) option:selected').val() == 'newtnm') {
      answer = mechanicalEfficiency*pressureValue*displacementValue/(200*Math.PI);
    } else if ($('#new-motor-calculator .torque-calculator-answer-units:not(.d-none) option:selected').val() == 'knewtnm') {
      answer = mechanicalEfficiency*pressureValue*displacementValue/(200*Math.PI*1000);
    } else if ($('#new-motor-calculator .torque-calculator-answer-units:not(.d-none) option:selected').val() == 'lbfin') {
      answer = mechanicalEfficiency*pressureValue*displacementValue*8.850745454/(200*Math.PI);
    } else if ($('#new-motor-calculator .torque-calculator-answer-units:not(.d-none) option:selected').val() == 'lbfft') {
      answer = mechanicalEfficiency*pressureValue*displacementValue*0.7375621212/(200*Math.PI);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator #answer-torque').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator #answer-torque').text('');
  }
}

function torqueCalc1() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val()) {
    let powerValue;
    if ($('#new-motor-calculator .torque-calculator-power-units:not(.d-none) option:selected').val() == 'watt') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val();
    } else if ($('#new-motor-calculator .torque-calculator-power-units:not(.d-none) option:selected').val() == 'kwatt') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val()*1000;
    } else if ($('#new-motor-calculator .torque-calculator-power-units:not(.d-none) option:selected').val() == 'hp') {
      powerValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#power').val()*745.7;
    }

    let speedValue;
    if ($('#new-motor-calculator .torque-calculator-speed-units:not(.d-none) option:selected').val() == 'rps') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val()*2*Math.PI;
    } else if ($('#new-motor-calculator .torque-calculator-speed-units:not(.d-none) option:selected').val() == 'rads') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val();
    } else if ($('#new-motor-calculator .torque-calculator-speed-units:not(.d-none) option:selected').val() == 'rpm') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator input#speed').val()*2*Math.PI/(60);
    }

    let answer;
    if ($('#new-motor-calculator .torque-calculator-answer-units:not(.d-none) option:selected').val() == 'newtnm') {
      answer = powerValue/(speedValue);
    } else if ($('#new-motor-calculator .torque-calculator-answer-units:not(.d-none) option:selected').val() == 'knewtnm') {
      answer = powerValue/(speedValue*1000);
    } else if ($('#new-motor-calculator .torque-calculator-answer-units:not(.d-none) option:selected').val() == 'lbfin') {
      answer = powerValue*8.850745454/(speedValue);
    } else if ($('#new-motor-calculator .torque-calculator-answer-units:not(.d-none) option:selected').val() == 'lbfft') {
      answer = powerValue*0.7375621212/(speedValue);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator #answer-torque').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.torque-calculator #answer-torque').text('');
  }
}

function powerCalculator() {
  $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input').on('input', function() {
    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').parent().parent().addClass('disabled-field');

      powerCalc1();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').parent().parent().removeClass('disabled-field');
    }

    if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flow-rate').val() || $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()) {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').parent().parent().addClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').prop('disabled',true);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').parent().parent().addClass('disabled-field');

      powerCalc2();

    } else {
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').parent().parent().removeClass('disabled-field');
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').prop('disabled',false);
      $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').parent().parent().removeClass('disabled-field');
    }
  });

  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val()) {
    powerCalc1();
  }
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()) {
    powerCalc2();
  }
}

function powerCalc1() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val()) {
    let torqueValue;
    if ($('#new-motor-calculator .power-calculator-torque-units:not(.d-none) option:selected').val() == 'newtnm') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val();
    } else if ($('#new-motor-calculator .power-calculator-torque-units:not(.d-none) option:selected').val() == 'knewtnm') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val()*1000;
    } else if ($('#new-motor-calculator .power-calculator-torque-units:not(.d-none) option:selected').val() == 'lbfin') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val()/8.850745454;
    } else if ($('#new-motor-calculator .power-calculator-torque-units:not(.d-none) option:selected').val() == 'lbfft') {
      torqueValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#torque').val()/0.7375621212;
    }

    let speedValue;
    if ($('#new-motor-calculator .power-calculator-speed-units:not(.d-none) option:selected').val() == 'rps') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val()*2*Math.PI;
    } else if ($('#new-motor-calculator .power-calculator-speed-units:not(.d-none) option:selected').val() == 'rads') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val();
    } else if ($('#new-motor-calculator .power-calculator-speed-units:not(.d-none) option:selected').val() == 'rpm') {
      speedValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#speed').val()*2*Math.PI/(60);
    }

    let answer;
    if ($('#new-motor-calculator .power-calculator-answer-units:not(.d-none) option:selected').val() == 'watt') {
      answer = torqueValue*speedValue;
    } else if ($('#new-motor-calculator .power-calculator-answer-units:not(.d-none) option:selected').val() == 'kwatt') {
      answer = torqueValue*speedValue/1000;
    } else if ($('#new-motor-calculator .power-calculator-answer-units:not(.d-none) option:selected').val() == 'hp') {
      answer = torqueValue*speedValue/745.7;
    }
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator #answer-power').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator #answer-power').text('');
  }
}

function powerCalc2() {
  if ($('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').val() && $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()) {
    let flowRateValue;
    if ($('#new-motor-calculator .power-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cmps') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').val();
    } else if ($('#new-motor-calculator .power-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cmpmin') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').val()/60;
    } else if ($('#new-motor-calculator .power-calculator-flowrate-units:not(.d-none) option:selected').val() == 'lpm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').val()/60000;
    } else if ($('#new-motor-calculator .power-calculator-flowrate-units:not(.d-none) option:selected').val() == 'gpm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').val()/15850.3;
    } else if ($('#new-motor-calculator .power-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cfm') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').val()/2118.88;
    } else if ($('#new-motor-calculator .power-calculator-flowrate-units:not(.d-none) option:selected').val() == 'cfs') {
      flowRateValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#flowrate').val()/35.315;
    }

    let pressureValue;
    if ($('#new-motor-calculator .power-calculator-pressure-units:not(.d-none) option:selected').val() == 'pascal') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val();
    } else if ($('#new-motor-calculator .power-calculator-pressure-units:not(.d-none) option:selected').val() == 'kpascal') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()*1000;
    } else if ($('#new-motor-calculator .power-calculator-pressure-units:not(.d-none) option:selected').val() == 'bar') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()*100000;
    }  else if ($('#new-motor-calculator .power-calculator-pressure-units:not(.d-none) option:selected').val() == 'psi') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()*6894.76;
    }  else if ($('#new-motor-calculator .power-calculator-pressure-units:not(.d-none) option:selected').val() == 'lbftsqr') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()*47.88;
    }  else if ($('#new-motor-calculator .power-calculator-pressure-units:not(.d-none) option:selected').val() == 'atm') {
      pressureValue = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#pressure').val()*101325;
    }

    let volumetricEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#volumetric-efficiency').val();
    let mechanicalEfficiency = $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator input#mechanical-efficiency').val();

    let answer;
    if ($('#new-motor-calculator .power-calculator-answer-units:not(.d-none) option:selected').val() == 'watt') {
      // answer = $('#new-motor-calculator .calculator-body-wrp .calculator-item.speed-calculator input#power').val();
      answer = flowRateValue*pressureValue*volumetricEfficiency*mechanicalEfficiency/10000;
    } else if ($('#new-motor-calculator .power-calculator-answer-units:not(.d-none) option:selected').val() == 'kwatt') {
      answer = flowRateValue*pressureValue*volumetricEfficiency*mechanicalEfficiency/(1000*10000);
    } else if ($('#new-motor-calculator .power-calculator-answer-units:not(.d-none) option:selected').val() == 'hp') {
      answer = flowRateValue*pressureValue*volumetricEfficiency*mechanicalEfficiency/(745.7*10000);
    }

    $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator #answer-power').text(answer.toFixed(3));
  } else {
    $('#new-motor-calculator .calculator-body-wrp .calculator-item.power-calculator #answer-power').text('');
  }
}
// New Calculator

// custom mtor design form
function customMotorForm() {
  $('.accordion-form-section').each(function() {
    $(this).find('.accordion-item-wrp').each(function() {
      $(this).find('.go-to-next-section').each(function() {
        $(this).click(function(){
          $(this).parents('.accordion-item-wrp').next().find('.faq-title').trigger('click');
          $('html, body').animate({
            scrollTop: $(this).parents('.accordion-item-wrp').offset().top,
          }, 'smooth');
        });
      });

      $(this).find('.go-to-submit').each(function() {
        $(this).click(function(){
          $(this).parents('.accordion-item-wrp').siblings('.accordion-item-wrp').find('.wpcf7-submit').trigger('click');
        });
      });
    });
    $(this).find('.screen-reader-response').appendTo($(this).find('.accordion-item-wrp.first .faq-title'));

    $(this).find('.submit-button-container').find('.wpcf7-submit').click(function(){
      $(this).parents('.accordion-form-section').find('.intro-content').addClass('submit-message');
      $('html, body').animate({
        scrollTop: $('.accordion-form-section').offset().top,
      }, 'smooth');
    });
  });
}
// custom mtor design form

// Function to scroll to a section based on the hash in the URL
function scrollToSection() {
  const hash = window.location.hash;
  if (hash) {

    const targetId = hash.substring(1);
    const $targetSection = $('#' + targetId);

    if ($targetSection.length) {
      $('html, body').animate({
          scrollTop: $targetSection.offset().top,
      }, 'smooth');
    }
  }
}
// Function to scroll to a section based on the hash in the URL


// Marine Calculator
function marineCalculator() {
  if ($('.marine-calculator')) {
    //On Change
    $('#marine-calculator input').on('keyup change', function() {
      runMarineCalculation();
    });
    //On Change
  }
}

function runMarineCalculation() {
  let yachtLength = $('#marine-calculator input#yacht-length').val();
  if (yachtLength < 0) {
    $('#marine-calculator input#yacht-length').val('15');
    setTimeout(function() {
      $('#marine-calculator input#yacht-length').trigger('change');
     }, 100);
  }
  let beam = $('#marine-calculator input#beam').val();
  if (beam < 0) {
    $('#marine-calculator input#beam').val('4');
    setTimeout(function() {
      $('#marine-calculator input#beam').trigger('change');
     }, 100);
  }
  // let draft = $('#marine-calculator input#draft').val();
  let displacement = $('#marine-calculator input#displacement').val();
  if (displacement < 0) {
    $('#marine-calculator input#displacement').val('21000');
    setTimeout(function() {
      $('#marine-calculator input#displacement').trigger('change');
    }, 100);
  }
  let desiredSpeed = $('#marine-calculator input#desired-speed').val();
  if (desiredSpeed < 0) {
    $('#marine-calculator input#desired-speed').val('8');
    setTimeout(function() {
      $('#marine-calculator input#desired-speed').trigger('change');
    }, 100);
  }
  let propEfficiency = $('#marine-calculator input#prop-efficiency').val();
  if (propEfficiency < 0 || propEfficiency > 100) {
    $('#marine-calculator input#prop-efficiency').val('60');
    setTimeout(function() {
      $('#marine-calculator input#prop-efficiency').trigger('change');
    }, 100);
  }
  let shaftLoss = $('#marine-calculator input#shaft-loss').val();
  if (shaftLoss < 0 || shaftLoss > 100) {
    $('#marine-calculator input#shaft-loss').val('90');
    setTimeout(function() {
      $('#marine-calculator input#shaft-loss').trigger('change');
    }, 100);
  }
  let electricalEfficiency = $('#marine-calculator input#electrical-efficiency').val();
  if (electricalEfficiency < 0 || electricalEfficiency > 100) {
    $('#marine-calculator input#electrical-efficiency').val('100');
    setTimeout(function() {
      $('#marine-calculator input#electrical-efficiency').trigger('change');
    }, 100);
  }
  let gearboxEfficiency = $('#marine-calculator input#gearbox-efficiency').val();
  if (gearboxEfficiency < 0 || gearboxEfficiency > 100) {
    $('#marine-calculator input#gearbox-efficiency').val('97');
    setTimeout(function() {
      $('#marine-calculator input#gearbox-efficiency').trigger('change');
    }, 100);
  }
  let propPitch = $('#marine-calculator input#prop-pitch').val();
  if (propPitch < 0) {
    $('#marine-calculator input#prop-pitch').val('12');
    setTimeout(function() {
      $('#marine-calculator input#prop-pitch').trigger('change');
    }, 100);
  }
  let gearRatio = $('#marine-calculator input#gear-ratio').val();
  if (gearRatio < 0) {
    $('#marine-calculator input#gear-ratio').val('1');
    setTimeout(function() {
      $('#marine-calculator input#gear-ratio').trigger('change');
    }, 100);
  }
  let propSlip = $('#marine-calculator input#prop-slip').val();
  if (propSlip < 0 || propSlip > 100) {
    $('#marine-calculator input#prop-slip').val('10');
    setTimeout(function() {
      $('#marine-calculator input#prop-slip').trigger('change');
    }, 100);
  }

  // Principal Particulars
  let C2 = yachtLength*0.95;
  let C3 = yachtLength;
  let C4 = beam;
  // let C5 = 1.5; // Can be a Input (Moulded)
  let C5 = $('#marine-calculator .modal input#moulded').val();
  if (C5 < 0) {
    $('#marine-calculator .modal input#moulded').val('1.5');
    setTimeout(function() {
      $('#marine-calculator .modal input#moulded').trigger('change');
    }, 100);
  }
  let C6 = C5;
  let C7 = displacement/1000;
  // let C8 = 1025; // Can be a Input (Density of sea water)
  let C8 = $('#marine-calculator .modal input#density-sea-water').val();
  if (C8 < 0 || C8 > 1050) {
    $('#marine-calculator .modal input#density-sea-water').val('1025');
    setTimeout(function() {
      $('#marine-calculator .modal input#density-sea-water').trigger('change');
    }, 100);
  }
  let C9 = 1.1584133*(10**(-6));
  let C10 = C7;
  // let C11 = 0; // Can be a Input (Trim)
  let C12 = desiredSpeed;
  let C13 = 9.81;
  let C14 = C12*0.5144/(Math.sqrt(C13*C2));
  // Principal Particulars
  let C71 = C2/C4;

  // Froude number ≤ 0.4
  let C17 = C12*0.5144*C2/C9;
  let C18 = 0.075/((Math.log10(C17))-2)**2;
  // let C19 = 0.1;
  let C19 = $('#marine-calculator .modal input#c-stern').val();
  if (C19 < 0) {
    $('#marine-calculator .modal input#c-stern').val('0.1');
    setTimeout(function() {
      $('#marine-calculator .modal input#c-stern').trigger('change');
    }, 100);
  }
  // let C20 = 1+0.011*C19;
  // let C21 = 8;
  // let C22 = 0.984;
  // let C23 = 0.837;
  // let C24 = 0.85;
  let C22 = $('#marine-calculator .modal input#midship-coeff').val();
  if (C22 < 0 || C22 > 1) {
    $('#marine-calculator .modal input#midship-coeff').val('0.984');
    setTimeout(function() {
      $('#marine-calculator .modal input#midship-coeff').trigger('change');
    }, 100);
  }
  let C23 = $('#marine-calculator .modal input#block-coeff').val();
  if (C23 < 0 || C23 > 1) {
    $('#marine-calculator .modal input#block-coeff').val('0.837');
    setTimeout(function() {
      $('#marine-calculator .modal input#block-coeff').trigger('change');
    }, 100);
  }
  let C24 = $('#marine-calculator .modal input#prismatic-coeff').val();
  if (C24 < 0 || C24 > 1) {
    $('#marine-calculator .modal input#prismatic-coeff').val('0.850');
    setTimeout(function() {
      $('#marine-calculator .modal input#prismatic-coeff').trigger('change');
    }, 100);
  }
  // Froude number ≤ 0.4

  // Form factor and Appendages
  let C27 = (C2/10)*4;
  let C28 = (C27-(C3/2))*100/C3;
  let C29 = C2*(1-C24+(0.06*C24*C28)/(4*C24-1))*(-1);
  let C30 = C2*C4*C24;
  let C31 = C30*0.1;
  let C32 = C30+C31;
  let C33 = 0.93+(0.4871*C19*((C4/C2)**1.0681))*((C6/C2)**0.4611)*((C2/C29)**0.1216)*(((C2**3)/C7)**0.3649)*((1-C24)**(-0.6042));
  // let C34 = 2.1;
  let C34 = $('#marine-calculator .modal input#appendages-form-factor').val();
  if (C34 < 0) {
    $('#marine-calculator .modal input#appendages-form-factor').val('2.1');
    setTimeout(function() {
      $('#marine-calculator .modal input#appendages-form-factor').trigger('change');
    }, 100);
  }
  let C35 = C33+((C34-C33)*C31/C32);
  // let C36 = 0.001*(0.5*C8*((C12*0.5144)**2)*C18*C32*C35);
  // let C37 = 0; // Can be a Input (mast area)
  // Form factor and Appendages


  // Wave-making Resistance Calculations
  let C61 = C4/C2;
  let C62;
  if (C61 <= 0.11) {
    C62 = 0.2296 * ( C61 ** 0.3333 );
  } else if (C61 <= 0.25) {
    C62 = 0.2296 * ( C61 ** 0.3333 );
  } else {
    C62 = 0.2296 * ( C61 ** 0.3333 );
  }
  let C63 = 0;
  let C58 = 0;
  if (C14 <= 0.4) {
    C63 = 2223105 * ( C62 ** 3.7861 ) * ( ( C6 / C4 ) ** 1.0796 ) * ( 90 - C58 ) **(- 1.3757);
  } else if (C14 >= 0.55) {
    C63 = 6919.3 * ( C22 **(- 1.3346) ) * ( ( C7 / ( C2 ** 3 ) ) ** 2.0098 ) * ( ( C71 - 2 ) ** 1.4069 );
  }
  let C64 = -0.9;
  let C65;
  if (C24 > 0.8) {
    C65 = 1.7301 - 0.7067 * C24;
  } else {
    C65 = 8.0798 * C24 - 13.8673 * ( C24 ** 2 ) + 6.9844 * ( C24 ** 3 );
  }
  let C66 = C6/C4;
  let C67 = 0;
  if (C14 <= 0.4) {
    C67 = 0.01404 * ( C2 / C6 ) - 1.7525 * ( ( C7 ** ( 1 / 3 ) ) / C2 ) - 4.7932 * ( C4 / C2 ) - C65;
  } else if (C14 >= 0.55) {
    C67 = (-7.2035) * ( C61 ** 0.3269 ) * ( C66 ** 0.6054 );
  }
  let C68 = (C2**3)/C7;
  let C69;
  if (C68 <= 512) {
    C69 = -1.69385;
  } else if (C68 <= 1727) {
    C69 = 1.69385 + ( ( C68 ** ( 1 / 3 ) ) - 8 ) / 2.36;
  } else {
    C69 = 0
  }
  let C70 = C69*0.4*Math.exp(-0.034*(C14**(-3.29)));
  let C72;
  if (C71 <= 12) {
    C72 = 1.446 * C24 - 0.03 * C71;
  } else {
    C72 = 1.446 * C24 - 0.36;
  }
  // let C73 = 0; // Can be a Input (Immersed area of the transom at zero speed)
  let C73 = $('#marine-calculator .modal input#immersed-area').val();
  if (C73 < 0) {
    $('#marine-calculator .modal input#immersed-area').val('0');
    setTimeout(function() {
      $('#marine-calculator .modal input#immersed-area').trigger('change');
    }, 100);
  }
  let C74 = 1-0.8*C73/(C4*C6*C22);
  // let C75 = C63*C57*C74*EXP(C67*(C14**C64))+C70*COS(C72*(C14**-2));
  let C57 = 0;
  let C75 = C63*C57*C74*Math.exp(C67*(C14**C64))+C70*Math.cos(C72*(C14**(-2)));
  let C40 = 0;
  let C76 = C40/C2;
  let C77;
  if (C76 >= 0.04) {
    C77 = 0.006 * ( ( C2 + 100 ) ** (- 0.16 ) ) - 0.00205;
  } else {
    C77 = 0.006 * ( ( C2 + 100 ) ** (- 0.16 ) ) - 0.00205 + 0.003 * ( ( C2 / 7.5 ) ** 2 ) * ( C23 ** 4 ) * C57 * ( 0.04 - C76 );
  }
  let C78 = C10*1000;
  let totalResistance = (0.001*(0.5*C8*((C12*0.5144)**2)*C32*(C18*C35+C77)+C75*C78)).toFixed(2);
  // Wave-making Resistance Calculations

  let theoreticalPowerValue = (totalResistance*C12*1852/3600);
  let actualPowerKWValue = (theoreticalPowerValue*(100**4)/(propEfficiency*shaftLoss*electricalEfficiency*gearboxEfficiency));
  let actualPowerHPValue = (actualPowerKWValue*1.341);
  let shaftSpeedValue = ((desiredSpeed*gearRatio*1215.2)/(propPitch*(1-propSlip/100)));

  let theoreticalPower;
  let actualPower;
  let shaftSpeed;
  if (isNaN(theoreticalPowerValue)) {
    theoreticalPower = 'Cannot Calculate';
  } else if (!isFinite(theoreticalPowerValue)) {
    theoreticalPower = 'Infinity';
  } else {
    theoreticalPower = `${theoreticalPowerValue.toFixed(1)} kW`;
  }

  if (isNaN(actualPowerKWValue)) {
    actualPower = 'Cannot Calculate';
  } else if (!isFinite(actualPowerKWValue)) {
    actualPower = 'Infinity';
  } else {
    actualPower = `${actualPowerKWValue.toFixed(1)} kW or ${actualPowerHPValue.toFixed(1)} hp`;
  }

  if (isNaN(shaftSpeedValue)) {
    shaftSpeed = 'Cannot Calculate';
  } else if (!isFinite(shaftSpeedValue)) {
    shaftSpeed = 'Infinity';
  } else {
    shaftSpeed = `${shaftSpeedValue.toFixed(1)} RPM`;
  }

  if (C14 > 0.4 && C14 < 0.55) {
    $('#marine-calculator .results-wrp #theoretical-power').text('Outside range of tool');
    $('#marine-calculator .results-wrp #actual-power').text('Outside range of tool');
    $('#marine-calculator .results-wrp #shaft-speed').text('Outside range of tool');
  } else {
    $('#marine-calculator .results-wrp #theoretical-power').text(`${theoreticalPower}`);
    $('#marine-calculator .results-wrp #actual-power').text(`${actualPower}`);
    $('#marine-calculator .results-wrp #shaft-speed').text(`${shaftSpeed}`);
  }
}
// Marine Calculator


// Fan Power Calculator
function fanPowerCalculator() {
  if ($('#fan-power-calculator')) {
    //On Change
    $('#fan-power-calculator input').on('keyup change', function() {
      runFanPowerCalculation();
    });
    //On Change
  }
}

function runFanPowerCalculation() {
  let pressureIncrease = $('#fan-power-calculator input#pressure-increase').val();
  if (pressureIncrease < 0) {
    $('#fan-power-calculator input#pressure-increase').val('120');
    setTimeout(function() {
      $('#fan-power-calculator input#pressure-increase').trigger('change');
     }, 100);
  }
  let airFlow = $('#fan-power-calculator input#air-flow').val();
  if (airFlow < 0) {
    $('#fan-power-calculator input#air-flow').val('1.5');
    setTimeout(function() {
      $('#fan-power-calculator input#air-flow').trigger('change');
     }, 100);
  }
  let fanEfficiency = $('#fan-power-calculator input#fan-efficiency').val();
  if (fanEfficiency < 0 || fanEfficiency > 100) {
    $('#fan-power-calculator input#fan-efficiency').val('80');
    setTimeout(function() {
      $('#fan-power-calculator input#fan-efficiency').trigger('change');
     }, 100);
  }
  let beltEfficiency = $('#fan-power-calculator input#belt-efficiency').val();
  if (beltEfficiency < 0 || beltEfficiency > 100) {
    $('#fan-power-calculator input#belt-efficiency').val('50');
    setTimeout(function() {
      $('#fan-power-calculator input#belt-efficiency').trigger('change');
     }, 100);
  }
  let motorEfficiency = $('#fan-power-calculator input#motor-efficiency').val();
  if (motorEfficiency < 0 || motorEfficiency > 100) {
    $('#fan-power-calculator input#motor-efficiency').val('75');
    setTimeout(function() {
      $('#fan-power-calculator input#motor-efficiency').trigger('change');
     }, 100);
  }

  let fanPower = pressureIncrease*airFlow*(100**3)/(fanEfficiency*beltEfficiency*motorEfficiency);
  let fanPowerValue;
  if (isNaN(fanPower)) {
    fanPowerValue = 'Cannot Calculate';
  } else if (!isFinite(fanPower)) {
    fanPowerValue = 'Infinity';
  } else if (fanPower < 0) {
    fanPowerValue = '';
  } else {
    fanPowerValue = `${fanPower.toFixed(1)} W`;
  }

  $('#fan-power-calculator .results-wrp #fan-power').text(`${fanPowerValue}`);

}
// Fan Power Calculator

function editorContentAdditions() {
  //wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
  var target = '.btn',
      invert = ':not(' + target + ')',
      wrap = '<div class="button-wrapper">',
      breakpoints = $('.editor-content-wrp > *'+invert);

  $('.editor-content-wrp').each(function( ) {
    $(this).find('.btn').unwrap();
  });

  breakpoints.each(function(){
    $(this).nextUntil(invert).wrapAll(wrap);
  });

  breakpoints.first().prevUntil(invert).wrapAll(wrap);
  //wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
}